<template>
  <div id="app">
    <header>
      <h1>杭州斯代拉科技有限公司</h1>
    </header>

    <main>
      <div class="main-image">
        <img :src="mainImage" alt="杭州斯代拉科技有限公司 主图">
      </div>
      <div class="overlay-text">
      <span>专业</span>
      <span>创新</span>
      <span>可靠</span>
    </div>
    </main>

    <footer>
      <p><a href="http://beian.miit.gov.cn/">浙ICP备2023022128号</a></p>
      <p>联系方式:15000416471</p>
      <p>联系地址: 浙江省杭州市余杭区中泰街道仙桥路16号丝腾中泰科技园1幢6楼6082室</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
   mounted() {
    document.title = '斯代拉科技';
  },
  data() {
    return {
      mainImage: require('@/assets/company_main_image.png')
    };
  }
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  flex: 1 0 auto;
}

.main-image img {
  width: 100%;
  max-height: 1000px;
  object-fit: cover;
}

main {
  flex: 1 0 auto; 
}

header{
  flex-shrink: 0;
  text-align: center; /* 使内容居中 */
  padding: 2px 0; /* 这将提供一些顶部和底部的间距 */
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}



header {
    z-index: 1;
    position: relative; /* z-index只对定位元素有效 */
    height: 60px; /* 例如设置高度为60像素，你可以根据需要调整 */
}

.main-image {
  height: 400px;
  background-color: #eee;
  display: flex; /* 使图片居中 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

footer  {
  margin: 5px 0;
  position: fixed;
  bottom: 0;
  text-align: center;
  background-color: #f5f5f5;
  height: 80px;
  width: 100%;
}

p{
  font-size: 18px;
  margin: 0;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: calc(50% + 300px);  /* 向右移动50px */
  transform: translate(-50%, -50%);
  display: flex;
  gap: 20px;
  color: #ffffff;
  font-weight: bold;
  font-size: 48px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

</style>

